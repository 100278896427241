<template>
  <div>
    <DaisyDrawer
      v-if="$slots.side"
      v-model="drawerOpen"
      responsive
      absolute
      class="h-full"
    >
      <template #side>
        <div class="w-full h-full bg-base-100 sm:w-96">
          <slot name="side" />
        </div>
      </template>
      <div class="w-full h-[calc(100vh-4rem)] overflow-y-auto px-4 py-2">
        <slot />
      </div>
    </DaisyDrawer>
    <div
      v-if="!$slots.side"
      class="w-full h-100vh overflow-y-auto px-4 md:px-16 py-2 md:py-4"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const drawerOpen = inject<boolean>("drawerOpen");
</script>

<style lang="scss">
.menu-title {
  @apply text-base-content;
}
</style>
